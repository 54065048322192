<template>
  <headBack>
    <template v-slot:title>
      <div class='title'>
            售后列表
      </div>
    </template>
  </headBack>
  <div class='header_cont'></div>
  <div class="wrapper">
    <div class="no_box" v-if="noMessage">
      <img src="https://static.yihu365.cn/img/h5Img/shop/no_order.png" alt="">
      <div>暂无售后订单</div>
    </div>
    <div class="list_box"
        v-for="(item,index) in listData"
    >
      <div class="top">
        <div>快天使医护到家商城</div>
        <div class="timer">{{ setStatus(item.afterSaleStatus) }}</div>
      </div>
      <van-card
          @click="toDetail(item)"
          v-for="(t,i) in item.orderData"
          :num="t.goodsNum"
          :price="t.payPrice"
          desc=""
          :title="t.goodsName"
          :thumb="t.iconUrl"
      />
    </div>
  </div>
</template>

<script>
import {setWxShareDate} from '@/libs/utils.js'
import { onMounted, ref, computed,reactive } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog ,Toast} from 'vant'
import shopApi from '@axios/shop'
import { channel } from '@/config/channel.js';
export default {
  name: "afterList",
  setup(){

    const store = useStore()
    const router = useRouter()

    const noMessage = ref(false)
    const Status = ref([
      {
        state:0,
        txt:'无售后'
      },
      {
        state:1,
        txt:'待审核'
      },
      {
        state:2,
        txt:'售后完成'
      },
      {
        state:3,
        txt:'撤销售后'
      },
      {
        state:4,
        txt:'商家审核失败'
      },

    ])
    const setStatus = (s) => {
      return Status.value.find(item => item.state == s).txt
    }
    const listData = ref('')
    const getList =()=> {
       const params ={
         userId:store.state.uid,
         pageNum:1,
         pageSize:20
       }
      shopApi.afterSalesList(params).then((res)=> {
         console.log(res)
        listData.value =res.data.pageData
        if(res.data.pageData.length > 0){
          noMessage.value = false;
        }else{
          noMessage.value = true;
        }
      })
    }
    getList()

    const toDetail =(item)=> {
       router.push({
          name:'afterDetail',
         query:{
            orderId:item.orderId
         }
       })
    }
    return {
      getList,
      listData,
      setStatus,
      toDetail,
      noMessage
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper{
  padding: 30px 0;
}
.list_box{
  color:#323232;
  width: 94%;
  margin:0 auto 20px;
  background: #fafafa;
  border-radius: 20px;
  padding:30px 0px;
.top{
  margin-bottom: 10px;
  font-size: 30px;
  padding:0 30px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
.timer{
  color:#00c291;
  display: flex;
  font-size: 28px;
  align-items: center;
}
}
::v-deep(.van-card){
//border-radius: 20px;
}
::v-deep(.van-button){
  border-radius: 8px;
  font-size: 24px;
}
::v-deep(.van-card:not(:first-child)){
  margin-top: 0;
}
::v-deep(.van-card:first-child){
//border-radius: 20px 20px 0 0;
}
::v-deep(.van-card:last-child){
//border-radius:0 0 20px 20px ;
}
.total_box{
  margin:10px 0;
  padding-right: 30px;
  text-align: right;
  font-size: 26px;
.jg{
  font-size: 32px;
}
}
.btn_box{
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
button{
  border:1px solid #ccc;
  margin-left:10px;
  background: #FFFFFF;
  font-size: 26px;
  border-radius: 26px;
  padding:4px 18px;
}
.red{
  color:#ee0a24;
  border:1px solid #ee0a24;
}
}
}

.no_box{
  color:#999999;
  padding-bottom: 20px;
  text-align: center;
  font-size: 28px;
  img{
    width: 300px;
  }
}
</style>
